<template>
  <div class="m-8">
    <v-row align="center" justify="center" class="button-print">
      <v-btn color="primary" @click="print"> IN PHIẾU ĐƠN THUỐC </v-btn>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="8">
        <p class="text-medium mb-0">CÔNG TY CỔ PHẦN BẢO ĐẠI ĐƯỜNG</p>
        <p class="mb-0">Phòng khám Chuyên khoa Y học cổ truyền</p>
        <span class="text-small">Trực thuộc Công ty cổ phần Bảo Đại Đường</span><br />
        <span class="text-small"
          >Trụ sở chính: 5A ngõ 58A, Hoàng Đạo Thành, Thanh Xuân, Hà Nội</span
        ><br />
        <span class="text-small">Địa chỉ: Số 146 ngõ 136, Tây Sơn, Đống Đa, Hà Nội</span
        ><br />
        <span class="text-small">Số điện thoại: 084.22.11.348 - 084.23.11.348</span><br />
      </v-col>
      <v-col class="flex">
        <div>
          <img src="../assets/logo/logo.png" alt="logo" class="w-15 h-12" />
        </div>
        <div>
          <p class="brand mt-1">BẢO ĐẠI ĐƯỜNG</p>
          <img
            class="qr-code mx-auto"
            src="https://res.cloudinary.com/dl2pj1j7w/image/upload/v1680756441/quanlyphongkham/baodaiduong/patient-prescription/4/oorv0yvnagicmpuwygux.png"
          />
        </div>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mt-4">
      <div class="text-h5 title">PHIẾU ĐƠN THUỐC</div>
    </v-row>
    <v-row class="mt-4 mb-0 py-0">
      <v-col cols="8" class="py-0">
        <p class="text-h7">Họ và tên: <span>xxxx</span></p>
      </v-col>
      <v-col cols="2" class="py-0">
        <p class="text-h7">Tuổi: <span>xxxx</span></p>
      </v-col>
      <v-col cols="2" class="py-0">
        <p class="text-h7">Giới tính: <span>xxxx</span></p>
      </v-col>
    </v-row>
    <p class="text-h7">Số Điện thoại: <span>xxxx</span></p>
    <p class="text-h7">Địa chỉ: <span>xxxx</span></p>
    <p class="text-h7">Triệu chứng: <span>xxxx</span></p>
    <p class="text-h7">Chẩn đoán: <span>xxxx</span></p>
    <p class="text-h7">Đơn thuốc: (có các vị thuốc)</p>
    <v-row class="my-4">
      <v-data-table
        class="table-data custom-border-table table-no-stripe"
        no-data-text="Không có dữ liệu"
        :headers="headers"
        :items="items"
        disable-pagination
        hide-default-footer
      />
    </v-row>
    <div class="border border-solid border-black mb-4"></div>
    <v-row>
      <v-col>
        <p class="mb-0">Lời dặn/kết luận của bác sỹ</p>
        <span class="break-words print-show" v-html="printShowNote"></span>
        <v-textarea
          v-model="modelNote"
          hide-details
          auto-grow
          rows="1"
          class="mt-0 pt-0 custom-prepend print-hidden"
        />
        <p class="mb-0 mt-8">
          Hẹn lịch tái khám: <span class="break-words">dd-mm-yyyy</span>
        </p>
      </v-col>
      <v-col>
        <p class="text-center mb-0">
          Ngày <span>xx</span> tháng <span>xx</span> năm <span>xxxx</span>
        </p>
        <p class="text-center font-bold">Chuyên gia/Bác sĩ điều trị</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required } from "~/utils/validation";

export default {
  name: "PhieuDonThuoc",
  data() {
    return {
      isInputPhone: false,
      phoneModel: null,
      valid: true,
      modelNote: "",
      rules: {
        required,
      },
      headers: [
        { text: "STT", value: "id", sortable: false, align: "left" },
        { text: "DƯỢC LIỆU", value: "name", sortable: false, align: "left" },
        { text: "ĐVT", value: "unit", sortable: false, align: "center" },
        { text: "ĐỊNH MỨC", value: "materialNorms", sortable: false, align: "left" },
        { text: "SỐ LƯỢNG", value: "quantity", sortable: false, align: "left" },
        { text: "TỔNG XUẤT", value: "total", sortable: false, align: "left" },
        { text: "GHI CHÚ", value: "note", sortable: false, align: "left" },
      ],
      items: [
        {
          id: 1,
          name: "xyz",
          unit: "g",
          materialNorms: "12.00",
          description: "xxxxxxxxx-xxxxxxxxxxx",
          quantity: 1,
          total: "12.00",
          note: "xxx",
        },
        {
          id: 2,
          name: "xyz",
          unit: "g",
          materialNorms: "6.0",
          description: "xxxxxxxxx-xxxxxxxxxxx",
          quantity: 1,
          total: "6.0",
          note: "xxx",
        },
      ],
    };
  },
  computed: {
    printShowNote() {
      return this.modelNote.replaceAll("\n", "<br>");
    },
  },
  methods: {
    print() {
      window.print();
    },
  },
};
</script>

<style type="sass" media="print" scoped>
@page {
  size: auto;
  margin: 0;
}

.print-show {
  display: none;
}

.print-hidden {
  display: block;
}

@media print {
  .print-show {
    display: block;
  }

  .print-hidden {
    display: none;
  }

  .button-print {
    display: none;
  }
}

p {
  margin-bottom: 4px;
  color: black !important;
  font-family: "SanFrancisco-Bold";
}

span {
  color: black !important;
  font-family: "SanFrancisco" !important;
}

.text-small {
  font-size: 14px;
}

.text-medium {
  font-size: 16px;
}

.text-bold {
  font-weight: bold;
}

.title {
  color: black !important;
  font-family: "SanFrancisco-Bold" !important;
  font-size: 30px !important;
}

.brand {
  color: black !important;
  font-family: "Mabella" !important;
  font-size: 30px !important;
}

.table-data {
  width: 100% !important;
  color: black !important;
}

.qr-code {
  width: 60px !important;
  height: 60px !important;
}
</style>
